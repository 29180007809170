<template>
  <div class="parent ">
    <img class="top-img" src="@/assets/img/login/top.png" alt="">
    <img class="left-img" src="@/assets/img/login/left.png" alt="">
    <div class="exit" @click="exit" v-if="isClient">退出</div>
    <div ref="loginCon" class="login-p">
      <div class="login-t">用户登录</div>
      <div class="login-box"
           v-loading="loading"
           element-loading-text="正在登陆..."
           element-loading-spinner="el-icon-loading"
           element-loading-background="rgba(0, 0, 0, 0.5)">
        <div class="tab-p">
          <div @click="changeType('user')" class="tab-item" :class="{'active': type == 'user'}">用户名登录</div>
          <div @click="changeType('phone')" class="tab-item" :class="{'active': type == 'phone'}">手机验证登录</div>
        </div>
        <div style="height:0.1rem;"></div>
        <div class="type-list" :style="{left: left}">
          <div class="type-item">
            <div class="input-tr">
              <img class="input-img" src="@/assets/img/login/user.png" alt="">
              <input v-model.trim="userName" v-if="type=='user'" class="input" type="text" placeholder="请输入用户名">
              <div v-else class="input"></div>
            </div>
            <div class="input-tr">
              <img class="input-img" src="@/assets/img/login/pass.png" alt="">
              <input v-model.trim="password" v-if="type=='user'" class="input" type="password" placeholder="请输入密码">
              <div v-else class="input"></div>
            </div>
          </div>
          <div class="type-item">
            <div class="input-tr">
              <img class="input-img" src="@/assets/img/login/phone.png" alt="">
              <input v-model.trim="phone" v-if="type=='phone'" class="input" type="text" placeholder="请输入手机号">
              <div v-else class="input"></div>
            </div>
            <div class="input-tr">
              <img class="input-img" src="@/assets/img/login/code.png" alt="">
              <input v-model.trim="code" v-if="type=='phone'" class="input code-input" type="text" maxlength="10"
                     placeholder="请输入验证码">
              <div v-else class="input code-input"></div>
              <div @click="sendCode" class="code-btn">
                <span v-if="count > 0">{{ count }}秒</span>
                <span v-else>发送验证码</span>
              </div>
            </div>
          </div>
        </div>
        <div class="rember-tr">
          <!-- <input id="ch-box" class="ch-box" type="checkbox"> -->
          <label v-if="isClient" for="ch-box" class="ch-label" @click="setting">设置</label>
          <div @click="forgetPass" class="forget">忘记密码？</div>
        </div>
        <div @click="login" class="login-btn">
          <span v-if="loading">{{ loadingText }}</span>
          <span v-else>登 录</span>
        </div>
      </div>
    </div>

    <forget ref="forget"></forget>
  </div>
</template>
<script>
import {login, sendMessage, getUserInfo} from '@/api/accountApi'
import {clientId, clientSecret, env} from '@/config/config'
import cookie from '@/util/cookie'
import forget from './forget'
import geetest from '@/util/geetest'

export default {
  components: {
    forget
  },
  computed: {
    left() {
      if (this.type == 'user') {
        return '0'
      }
      if (this.type == 'phone') {
        return '-100%'
      }
    },
    isClient() {
      return this.$store.getters['user/isClient']
    }
  },
  data() {
    return {
      type: 'user',
      clientId: clientId,
      clientSecret: clientSecret,
      userName: '',
      password: '',
      phone: '',
      code: '',
      loading: false,
      loadingText: '',

      count: localStorage.count ? parseInt(localStorage.count) : 0
    }
  },
  methods: {
    setting() {
      QCefClient.invokeMethod('toLink', 'setting')
    },
    exit() {
      QCefClient.invokeMethod('toLink', 'exit')
    },
    changeType(type) {
      this.type = type
    },
    login() {
      if (this.type == 'user') {
        this.userLogin()
      } else if (this.type == 'phone') {
        this.phoneLogin()
      }
    },
    async userLogin() {
      if (!this.userName) {
        this.$message.error('请输入用户名')
        return
      }
      if (!this.password) {
        this.$message.error('请输入密码')
        return
      }
      this.loading = true
      this.loadingText = '正在登录...'
      try {
        var res = await login({
          client_id: this.clientId,
          client_secret: this.clientSecret,
          grant_type: 'password',
          username: this.userName,
          password: this.password
        })

       
        this.$store.commit('user/setToken', res)
        const result = await getUserInfo({userName: this.userName})
        this.$store.commit('user/setUserInfo', {info:result.userInfo, redirectUrl: result.redirectUrl})
       
        // return this.$router.replace('city')
        let redirectUrl = result.redirectUrl

        if(location.host == 'local.fjfuyu.net'){
            redirectUrl = redirectUrl.replace('https://test.luomi.cn/dash', 'http://local.fjfuyu.net')
        } 
        
        if (redirectUrl) {
          var path = redirectUrl.split('#')[0]
          var hash = redirectUrl.split('#')[1]
          var random = Math.random()
          if(hash){
              location.href = `${path}?v=${random}#${hash}`
          } else {
              
              location.href = `${path}?v=${random}`
          }
        
        } else {
          // this.$router.replace('county')
          this.$message.error('没有权限，无法访问')
        }
      } catch (error) {
        console.log(error)
      }

      this.loading = false
    },
    async phoneLogin() {
      if (!this.phone) {
        this.$message.error('请输入手机号码')
        return
      }
      if (!this.code) {
        this.$message.error('请输入验证码')
        return
      }
      this.loading = true
      this.loadingText = '正在登录...'
      try {
        var res = await login({
          client_id: this.clientId,
          client_secret: this.clientSecret,
          grant_type: 'password',
          username: this.phone,
          password: this.code
        })

        this.$store.commit('user/setToken', res)

        const result = await getUserInfo({userName: this.phone})
        this.$store.commit('user/setUserInfo', {info:result.userInfo, redirectUrl:result.redirectUrl})


        let redirectUrl = result.redirectUrl
        if(location.host == 'local.fjfuyu.net'){
            redirectUrl = redirectUrl.replace('https://test.luomi.cn/dash', 'http://local.fjfuyu.net')
        } 

        if (redirectUrl) {
          var path = redirectUrl.split('#')[0]
          var hash = redirectUrl.split('#')[1]
          var random = Math.random()
          if(hash){
              location.href = `${path}?v=${random}#${hash}`
          } else {
              
              location.href = `${path}?v=${random}`
          }
          
        } else {
          // this.$router.replace('county')
          this.$message.error('没有权限，无法访问')
        }
      } catch (error) {

      }
      this.loading = false
    },
    async sendCode() {
      if (this.count > 0) {
        return
      }
      if (!this.phone) {
        this.$message.error('请输入手机号码')
        return
      }
      if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone))) {
        this.$message.error('手机号码不正确')
        return
      }
      this.loading = true
      this.loadingText = ''
      try {
        await geetest.getGeetest(this.phone)
        await geetest.sendMessage(this.phone)
        if (res) {
          this.$message.success('验证码已发送')

          this.setCount(60)
          this.countDown()
        }
      } catch (error) {

      }

      this.loading = false
    },
    countDown() {
      this.setCount(this.count - 1)
      if (this.count == 0) {
        return
      }
      setTimeout(() => {
        this.countDown()
      }, 1000)
    },
    setCount(val) {
      this.count = val
      localStorage.count = val
    },
    forgetPass() {
      this.$refs.forget.open()
    }
  },
  mounted() {
    if (this.count > 0) {
      this.countDown()
    }

    this.$refs.loginCon.onkeydown = (event) => {
      var e = event || window.event
      if (e && e.keyCode == 13) {
        this.login()
      }
    }

  }
}
</script>
<style scoped>
.parent {
  height: 100%;
  width: 100%;
  background: url(../../assets/img/login/bg.png);
  background-size: 100% 100%;

}

.top-img {
  max-width: 100%;
}

.left-img {
  position: absolute;
  left: 1.3rem;
  top: 1.8rem;
  width: 8.5rem;
  height: 6.4rem;
}

.login-p {
  position: absolute;
  right: 3rem;
  top: 1.7rem
}

.login-t {
  text-align: center;
  color: #fff;
  font-size: 0.32rem;
  padding: 0 0 0.24rem 0;
}

.login-box {
  background: url(../../assets/img/login/login-bg.png);
  background-size: cover;
  width: 4.78rem;
  height: 5rem;
  overflow: hidden;
}

.tab-p {
  margin: 0 0.5rem;
  padding: 0.5rem 0 0 0;
  border-bottom: #092452 0.02rem solid;
  font-size: 0;
}

.tab-item {
  cursor: pointer;
  color: #fff;
  font-size: 0.22rem;
  display: inline-block;
  vertical-align: middle;
  width: 50%;
  text-align: center;
  padding: 0 0 0.3rem 0;
  border-bottom: 0.04rem solid transparent;
}

.active {
  border-bottom: 0.04rem solid #00A0F5
}

.input-tr {
  margin: 0.33rem 0.5rem 0 0.5rem;
  position: relative;
}

.input-img {
  position: absolute;
  left: 0.22rem;
  top: 0.15rem;
  width: 0.2rem;
  height: 0.26rem;
}

.input {
  width: 3.76rem;
  height: 0.56rem;
  box-sizing: border-box;
  border: 1px solid #015485;
  border-radius: 0.05rem;
  background: none;
  outline: none;
  padding: 0.18rem 0.18rem 0.18rem 0.6rem;
  color: #fff;
  font-size: 0.18rem;
}

.input:focus {
  border: 1px solid #00E4FF;
}

.type-list {
  white-space: nowrap;
  position: relative;
  left: 0;
  transition: 0.5s;
}

.type-item {
  display: inline-block;
  vertical-align: middle;
  width: 4.78rem;
}

.code-btn {
  color: #00B8FF;
  font-size: 0.16rem;
  border-left: 1px solid #00B8FF;
  padding: 0 0 0 0.18rem;
  position: absolute;
  top: 0.15rem;
  right: 0.1rem;
  cursor: pointer;
}

.ch-box {
  height: 0.2rem;
  width: 0.2rem;
  vertical-align: middle;
  border: 1px solid #00B8FF;
  margin: 0;
}

.ch-label {
  cursor: pointer;
  color: #fff;
  font-size: 0.16rem;
  vertical-align: middle;
}

.rember-tr {
  position: relative;
  margin: 0.28rem 0.5rem 0 0.5rem;
  height: 0.25rem;
}

.forget {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #00B8FF;
}

.login-btn {
  width: 3.76rem;
  height: 0.6rem;
  line-height: 0.6rem;
  text-align: center;
  font-size: 0.26rem;
  color: #fff;
  background: #00A0F5;
  border-radius: 0.05rem;
  cursor: pointer;
  margin: 0.3rem auto 0 auto;
}

.exit {
  color: #fff;
  position: absolute;
  top: 0.3rem;
  right: 1rem;
  cursor: pointer;
}
</style>
<style type="text/css">
.login-p input:-internal-autofill-selected {
  box-shadow: inset 0 0 0 1000px #092452 !important;
  -webkit-text-fill-color: #fff;
}
</style>
