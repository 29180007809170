<template>
    <div>
        <cvue-dialog
            :dialogVisible='dialogVisible'
            title='忘记密码'
            :isShowFoot='true'
         dialogWidth='30%'
            @handleOpen='getDetail'
            @closeDialog='closeDialog'
            @confirmDialog='confirmDialog'
            @handleClose='closeDialog'
           >
            <div class="dialog-body" slot="dialogBody"
             v-loading="loading"
             element-loading-text=""
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0)">
               
                <el-form label-width="100px" :model="ruleForm" :rules="rules" ref="ruleForm">
                    <el-form-item label="手机号码" prop="phone">
                        <el-input v-model="ruleForm.phone"></el-input>
                    </el-form-item>
                    <el-form-item label="手机验证码" prop="code">
                        <el-input  v-model="ruleForm.code" style="width:1rem;" autocomplete="off"></el-input>
                        <el-button type="primary" @click="sendCode" plain>
                             <span v-if="count > 0">{{count}}秒</span>
                                <span v-else>发送验证码</span>
                        </el-button>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                        <el-input  v-model="ruleForm.password" type="password" autocomplete="off"></el-input>
                        
                    </el-form-item>
                    <el-form-item label="重复密码" prop="confirmPassword">
                        <el-input  v-model="ruleForm.confirmPassword"  type="password" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </cvue-dialog>
    </div>
</template>
<script>
import cvueDialog from '@/components/cvue-dialog'
import {login, sendMessage, forgetPwd} from '@/api/accountApi'
import geetest from '@/util/geetest'
export default {
    components: {
        cvueDialog
    },
    props: {
       
    },
    data () {
        return {
            dialogVisible: false,
            rules: {
                phone: [
                     { required: true, message: '请输入手机号码', trigger: 'change' },
                    { validator: (rule, value, callback) => {
                        var flag = /^1(3|4|5|6|7|8|9)\d{9}$/.test(value)
                        console.log(value, flag)
                        if(flag){ 
                            callback()
                        } else {
                            callback(new Error('请输入正确的手机号码'))
                        }
                    }, trigger: 'change' }
                ],
                code: [
                    { required: true, message: '请输入验证码', trigger: 'change' },
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'change' },
                    { min: 6, max: 20, message: '长度在 6 到 20', trigger: 'change' }
                ],
                 confirmPassword: [
                    { required: true, message: '请输入重复密码', trigger: 'change' },
                    { validator: (rule, value, callback) => {
                        if(this.ruleForm.password != value){ 
                           
                            callback(new Error('密码不一样'))
                        } else {
                             callback()
                        }
                    }, trigger: 'change' }
                ]
            },
            ruleForm: {
                phone: '',
                password: '',
                confirmPassword: '',
                code: '',
                platform: 'smartcampus'
            },

            count: localStorage.count ? parseInt(localStorage.count) : 0,
            loading: false
        }
    },
    methods: {
        getDetail () {
            setTimeout(() => {
                this.$refs.ruleForm.resetFields()
            }, 1000)

            
            if (this.count > 0) {
                this.countDown()
            }
        },
        closeDialog () {
            this.dialogVisible = false
        },
        async confirmDialog () {
            await this.validate()
            this.loading = true
            try {
                var res = await forgetPwd({
                    phone: this.ruleForm.phone,
                    password: this.ruleForm.password,
                    confirmPassword:this.ruleForm.confirmPassword,
                    code: this.ruleForm.code,
                    platform: this.ruleForm.platform,
                })
                if (res) {
                    this.$message.success('密码已修改')
                    this.closeDialog()
                }
                 
            } catch (error) {
             
            }
            
           this.loading = false
            
           
        },
        open () {
            this.dialogVisible = true
        },
        validate () {
            return new Promise((resolve, reject) => {
                 this.$refs.ruleForm.validate((valid) => {
                     if (valid) {
                         resolve()
                     } else {
                         reject()
                     }
                }) 
            })
           
        },
        async sendCode () {
            if (this.count > 0) {
                return
            }
            if (!this.ruleForm.phone) {
                this.$message.error('请输入手机号码')
                return
            }
            if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.ruleForm.phone))){ 
                this.$message.error('手机号码不正确')
                return
            } 
            this.loading = true
            try {
              
                await geetest.getGeetest(this.ruleForm.phone)
                await geetest.sendMessage(this.ruleForm.phone)
            
                if (res) {
                    this.$message.success('验证码已发送')
                
                    this.setCount(60)
                    this.countDown()
                }
            } catch (error) {
                
            }
            

             this.loading = false
            
        },
        countDown () {
             this.setCount(this.count - 1)
            if (this.count == 0) {
                return
            }
            setTimeout(() => {
                this.countDown()
            }, 1000)
        },
        setCount (val) {
            this.count = val
            localStorage.count = val
        },
    }
}
</script>
<style scoped>

</style>
