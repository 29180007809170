import {login, sendMessage,geetest, getUserInfo} from '@/api/accountApi'
export default {
     getGeetest(loginname){
         return new Promise(async (resolve, reject) => {
            let res = await geetest({
                loginname: loginname,
                mark: 'commandcenter',
                projectType: 5,
                clientType: 1
              })
              
                
              var data = res.data
              // challenge: "fdca5322e748cbb3c82b1a0cdb0b5bc8"
              // gt: "4199cf071104b580b71936996fbbb072"
              // success: 1

              function handlerGeetest(obj){
                  window.captchaObj = obj
                  captchaObj.appendTo("#validCode");
                  captchaObj.onReady(function () {
                      captchaObj.verify()
                     
                  });
                  captchaObj.onError(function (error) {
                      captchaObj.reset()
                  });
                  captchaObj.onSuccess(function () {
                      
                      resolve()
                  })
              }

              // 调用 initGeetest 初始化参数
              // 参数1：配置参数
              // 参数2：回调，回调的第一个参数验证码对象，之后可以使用它调用相应的接口
              console.log(data)
              initGeetest({
                  gt: data.gt,
                  challenge: data.challenge,
                  // new_captcha: data.new_captcha, // 用于宕机时表示是新验证码的宕机
                  // offline: !data.success, // 表示用户后台检测极验服务器是否宕机，一般不需要关注
                  product: "bind", // 产品形式，包括：float，popup
                  width: "100%"
              }, handlerGeetest);
         })
        
    },
    async sendMessage(phone){
        var result = captchaObj.getValidate();
        await sendMessage({
            phone: phone,
            mark: "commandcenter",
            projectType: 5,
            clientType: 1,

            geetest_challenge: result.geetest_challenge,
                geetest_seccode: result.geetest_seccode,
                geetest_validate: result.geetest_validate
        })
      
    }
   
}