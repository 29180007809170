<template>
  <div>
     <!-- 健康监测 -->
    <defence class="party" ></defence>

     <defenceModal
            :modelShow="defenceModalShow" 
            @handelClose="$store.state.county.defenceModalShow = false">
        </defenceModal>
  </div>
</template>
<script>
import defence from "@/components/dataPart/defence";
import defenceModal from '@/components/modal/defenceModal'
export default {
  components: {
    defence, defenceModal
   
  },
  computed: {
     defenceModalShow () {
            return this.$store.state.county.defenceModalShow
        },
  },
  data(){
    return {
      id:''
    }
  },
  methods:{
    openDangerousInfoModal(id){
      this.id = id
      this.$refs.dangerousInfoModal.open()
    }
  }
};
</script>

<style scoped>
</style>